import React from "react";

//Style
import "./DocCertificationElementComponent.css";

const DocCertificationElementComponent = ({
  docData,
  customFunction,
  type
}) => {
  const handleClick = () => {
    customFunction({
      clientId: docData.IdClient,
      creditId: docData.credito,
      nameCertificate: type
    });
  };

  return (
    <label className="dct-sdl-info-elm-container">
      {docData && (
        <>
          <input
            className="dct-sdl-info-elm-checkbox"
            type="checkbox"
            name=""
            id={docData.credit}
            onClick={handleClick}
          />
          <p className="dct-sdl-info-elm-txt">
            {docData.Document} - {docData.credito}
          </p>
        </>
      )}
    </label>
  );
};

export default DocCertificationElementComponent;
