import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

//Components
import NavbarComponent from "../../components/NavbarComponent/NavbarComponent";
import SideMenusComponent from "../../components/SideMenusComponent/SideMenusComponent";
import LoadingScreenComponent from "../../components/LoadingScreenComponent/LoadingScreenComponent";
import NavbarMobile from "../../components/NavbarMobile/NavbarMobile";
import NavbarMobileBottom from "../../components/NavbarMobileBotton/NavbarMobileBottom";
import VersionTxtComponent from "../../components/VersionTxtComponent/VersionTxtComponent";
import VerificationUserDataBody from "../../components/VerificationUserDataBody/VerificationUserDataBody";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const VerificationUserDataScreen = ({ userData, userMenuData }) => {
  const { loading } = useContext(LoadingContext);

  const history = useNavigate();

  const currentStep = localStorage.getItem("latestStep");

  return (
    <>
      {loading && <LoadingScreenComponent />}
      <div className="body-container">
        <VersionTxtComponent />
        <div className="body-subcontainer">
          <NavbarComponent userData={userData} />
          <NavbarMobile userData={userData} menuData={userMenuData} />
          <VerificationUserDataBody />
          <NavbarMobileBottom />
        </div>
        <SideMenusComponent menuData={userMenuData} />
      </div>
    </>
  );
};

export default VerificationUserDataScreen;
