import React, { useEffect, useContext, useState } from "react";
import { BACKEND_API } from "../../../constants/backendApi";

//Style
import "./DocCertificationBody.css";

//Components
import DocCertificationTableComponent from "../../DocCertificationTableComponent/DocCertificationTableComponent";

//Context
import { LoadingContext } from "../../../context/LoadingContext";
import { PopupContext } from "../../../context/PopupContext";

//hooks
import useDebounce from "../../../hooks/useDebounce";

const DocCertificationBody = ({ userData }) => {
  const { setLoading } = useContext(LoadingContext);

  const [maxData, setMaxData] = useState(10);

  const [initPage, setInitPage] = useState(0);

  const token = localStorage.getItem("token");

  const [peaceAndBondCredits, setPeaceAndBondCredits] = useState([]);

  const [creditStatus, setCreditStatus] = useState([]);

  const [paymentReceiptCredits, setPaymentReceiptCredits] = useState([]);

  const [filterData, setFilterData] = useState({
    type: "",
    document: "",
    advisor: "",
    insurer: "",
    credit_state: "",
    next_to_renew: "",
  });

  const debouncedSearch = useDebounce((filterData) => {
    setLoading(true);
    
    const filterBody = {
      Filtro: filterData.document,
      EstadoConsulta: "",
      IdAseguradora: filterData.insurer,
      Estado: "",
      AllData: true,
      Renovacion: false,
      Cancelados: false,
      EnprocesoCancelacion: false,
      Mora: false,
      EnProceso: false,
      NumeroRegistros: maxData,
      Inicial: initPage,
      Anio: "0",
      Mes: "0",
    };

    try {
      fetch(`${BACKEND_API}/api/credits`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filterBody),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data.Creditos) {
            setCreditStatus(data.Creditos);

            // Filtrar créditos que contengan "Paz y Salvo" en el estado
            const filteredCreditsPeaceAndBond = data.Creditos.filter((credit) =>
              credit.Estado.includes("Paz y Salvo")
            );
            setPeaceAndBondCredits(filteredCreditsPeaceAndBond);

            // Filtrar créditos con estados "Vigente" o "En Proceso"
            const filteredCreditsPaymentReceipt = data.Creditos.filter(
              (credit) =>
                credit.Estado === "Vigente" || credit.Estado === "En proceso"
            );
            setPaymentReceiptCredits(filteredCreditsPaymentReceipt);
          } else {
            setCreditStatus([]);
            setPaymentReceiptCredits([]);
            setPeaceAndBondCredits([]);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, 500);

  useEffect(() => {
    debouncedSearch(filterData);
  }, [maxData, initPage, filterData]);

  const handleGetFilters = (e) => {
    if (e.target.name === "document") {
      setFilterData({
        ...filterData,
        type: e.target.value !== "" ? "Documento_Placa" : "",
        [e.target.name]: e.target.value,
      });
    }

    if (
      e.target.name === "advisor" ||
      e.target.name === "insurer" ||
      e.target.name === "credit_state"
    ) {
      setFilterData({
        ...filterData,
        type: "FiltrosVarios",
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCleanFilters = () => {
    setFilterData({
      type: "",
      document: "",
      advisor: "",
      insurer: "",
      credit_state: "",
      next_to_renew: "",
    });
  };

  return (
    <section className="doc-certification-section">
      <div className="doc-certification-subcontainer">
        <div className="filter-elms-container">
          <div className="input-container">
            <form onChange={handleGetFilters} className="filter-form-container">
              <label>
                <h1 className="label-title">Número de Documento:</h1>
                <input
                  className="filter-input-search"
                  type="text"
                  placeholder="Número de Documento"
                  title="Numero de Documento"
                  name="document"
                  value={filterData.document}
                />
              </label>
            </form>
            <button
              className="c-btn-search"
              title="Limpiar Filtros"
              onClick={handleCleanFilters}
            >
              Limpiar Filtros
            </button>
          </div>
        </div>
        <div className="dc-table-container">
          <DocCertificationTableComponent
            creditStatusList={creditStatus}
            peaceAndBondCreditList={peaceAndBondCredits}
            paymentReceiptCreditList={paymentReceiptCredits}
          />
        </div>
      </div>
    </section>
  );
};

export default DocCertificationBody;
