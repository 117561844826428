import React, { useContext, useEffect, useState } from "react";
import { encode as base64_encode } from "base-64";
import { useNavigate } from "react-router-dom";
import { BACKEND_API } from "../../constants/backendApi";

//Style
import "./LoginForm.css";

//Components
import ButtonComponent from "../ButtonComponent/ButtonComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";
import { PopupContext } from "../../context/PopupContext";
import { AuthContext } from "../../context/AuthContext";
import { authTypes } from "../../types/authTypes";

const LoginForm = ({ formData }) => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);

  const [showPass, setShowPass] = useState(false);

  const [blockBtn, setBlockBtn] = useState(false);

  const { setLoading } = useContext(LoadingContext);

  const { setShowPopup, setPopupData } = useContext(PopupContext);

  const { dispatch } = useContext(AuthContext);

  const [loginData, setLoginData] = useState({
    document: "",
    password: "",
  });

  const [userDocument, setUserDocument] = useState("");

  useEffect(() => {
    switch (currentStep) {
      case 0:
        setLoginData({
          document: "",
          password: "",
        });
        break;
      case 1:
        setLoginData({
          send_document: "",
        });
        break;
      case 2:
        setLoginData({
          document: userDocument,
          otp: "",
          new_password: "",
        });
        break;
    }
  }, [currentStep]);

  const handleForm = (e) => {
    if (e.target.title == "number") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");

      if (e.target.value.trim() === "") {
        e.target.nextElementSibling.classList.add("show-error");
      } else {
        e.target.nextElementSibling.classList.remove("show-error");
      }
    }

    if (e.target.title == "password") {
      if (e.target.value.trim() === "") {
        e.target.nextElementSibling.classList.add("show-error");
      } else {
        e.target.nextElementSibling.classList.remove("show-error");
      }
    }

    e.target.name === "send_document" &&
      setUserDocument(loginData.send_document);

    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    switch (currentStep) {
      case 0:
        if (
          loginData.document &&
          loginData.document !== "" &&
          loginData.password &&
          loginData.password !== ""
        ) {
          let encoded = base64_encode(loginData.password);
          const body = {
            document: loginData.document,
            password: encoded,
          };
          fetch(`${BACKEND_API}${formData[currentStep].endpoint}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          })
            .then((res) => {
              const statusCode = res.status;

              return res.json().then((data) => {
                data.status = statusCode;
                return data;
              });
            })
            .then((data) => {
              if (data.status === 200) {
                dispatch({ type: authTypes.login });
                localStorage.setItem("token", data.data);
                navigate(formData[currentStep].redirect);
              } else if (data.status === 401) {
                setShowPopup(true);
                setPopupData({
                  title: "Error",
                  info: "Contraseña incorrecta, demasiados intentos de inicio de sesión, intentalo de nuevo en 30 minutos.",
                  type: "error",
                });
              } else {
                setShowPopup(true);
                setPopupData({
                  title: "Error",
                  info: "Credenciales Invalidas",
                  type: "error",
                });
              }
            })
            .finally(() => {
              setLoading(false);
            });
        }
        break;
      case 1:
        if (loginData.send_document && loginData.send_document !== "") {
          const body = {
            document: loginData.send_document,
          };
          fetch(`${BACKEND_API}${formData[currentStep].endpoint}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          })
            .then((res) => {
              const statusCode = res.status;

              return res.json().then((data) => {
                data.status = statusCode;
                return data;
              });
            })
            .then((data) => {
              if (data.status === 200) {
                setUserDocument(loginData.send_document);
                setCurrentStep(2);
                setShowPopup(true);
                setPopupData({
                  info: "Correo Eléctronico Enviado Exitosamente",
                  type: "correct",
                });
              } else {
                setShowPopup(true);
                setPopupData({
                  title: "Error",
                  info: "Documento no Registrado",
                  type: "error",
                });
              }
            })
            .finally(() => {
              setLoading(false);
            });
        }
        break;
      case 2:
        if (
          loginData.document &&
          loginData.document !== "" &&
          loginData.otp &&
          loginData.otp !== "" &&
          loginData.new_password &&
          loginData.new_password !== ""
        ) {
          let encodedNewPass = base64_encode(loginData.new_password);
          const body = {
            document: loginData.document,
            otp: loginData.otp,
            newPass: encodedNewPass,
          };
          fetch(`${BACKEND_API}${formData[currentStep].endpoint}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          })
            .then((res) => {
              const statusCode = res.status;

              return res.json().then((data) => {
                data.status = statusCode;
                return data;
              });
            })
            .then((data) => {
              if (data.status === 200) {
                setShowPopup(true);
                setPopupData({
                  info: "Contraseña Actualizada Correctamente",
                  type: "correct",
                });
                setCurrentStep(0);
              } else {
                setShowPopup(true);
                setPopupData({
                  title: "Error",
                  info: "Datos Invalidos",
                  type: "error",
                });
              }
            })
            .finally(() => {
              setLoading(false);
            });
        }
        break;
    }
  };

  return (
    <div className="login-form-container">
      <div className="login-input-container">
        {formData[currentStep].hasTitle && (
          <div className="login-form-title">
            <h1>{formData[currentStep].title}</h1>
          </div>
        )}
        <form
          onSubmit={handleSubmit}
          onChange={handleForm}
          className="form-supercontainer"
        >
          <div className="login-fields-container">
            {formData[currentStep].inputs.map((input, i) => {
              return (
                <label className="login-field-container" key={i}>
                  {input.type === "password" && (
                    <button
                      onClick={() => setShowPass(!showPass)}
                      type="button"
                      className="show-pass"
                    >
                      <img
                        className="show-pass-icon"
                        src={
                          showPass
                            ? "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/show-pass-icon.webp"
                            : "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/hide-pass-icon.webp"
                        }
                        alt=""
                      />
                    </button>
                  )}
                  <input
                    type={
                      input.type === "password"
                        ? showPass
                          ? "text"
                          : "password"
                        : input.type
                    }
                    title={input.title}
                    required={input.mandatory}
                    name={input.name}
                    autoComplete="off"
                    placeholder={input.text}
                    value={loginData[input.name]}
                  />
                  <p className="txt-error">Campo obligatorio</p>
                </label>
              );
            })}
          </div>
          {formData[currentStep].hasTextBtn && (
            <div className="text-btn-container">
              <p
                onClick={() => setCurrentStep(formData[currentStep].btnAction)}
              >
                {formData[currentStep].textBtn}
              </p>
            </div>
          )}
          <div className="buttons-container">
            {formData[currentStep].buttons.map((btn) => {
              return (
                <ButtonComponent
                  btnData={btn}
                  hasBlock={true}
                  block={blockBtn}
                />
              );
            })}
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
