import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

//Style
import "./FormDocsComponent.css";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const FormDocsComponent = ({ inputData, getSelectedValue }) => {
  const { setLoading, setHasComplementTxt } = useContext(LoadingContext);

  const location = useLocation();

  const history = useNavigate();

  const [plate, setPlate] = useState("");

  const [insuranceVal, setInsuranceVal] = useState("");

  const [doc, setDoc] = useState(null);

  const [ccType, setCcType] = useState("");

  const [docType, setDocType] = useState("");

  const [inputTextInfo, setInputTextInfo] = useState("");

  const handleInput = (e) => {
    if (e.target.name === "cc-type") {
      getSelectedValue(e.target.value);
      setCcType(e.target.value);
    }

    if (e.target.name === "insurer-name") {
      getSelectedValue(e.target.value);
      if (
        e.target.value.includes("MUNDIAL") ||
        e.target.value.includes("SOLIDARIA") ||
        e.target.value.includes("EQUIDAD") ||
        e.target.value.includes("SURA")
      ) {
        const valueInsurerArr = e.target.value.split("+");
        const docTypeVal = `formato_${valueInsurerArr[0]}`;
        setDocType(docTypeVal);
        setInsuranceVal(valueInsurerArr[1]);
      } else {
        setInsuranceVal(e.target.value);
      }
    }

    if (e.target.name === "doc") {
      setDoc(e.target.files[0]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setHasComplementTxt(true);

    const formData = new FormData();

    const baseEndpoint = inputData.endpoint;

    const uuid = uuidv4();
    const valuesArr = uuid.split("-");

    let endpointWithParams = "";

    if (location.pathname === "/admin/crear-credito/adjuntar-documento") {
      localStorage.setItem("latestStep", "attach-document");

      const idFromLS = localStorage.getItem("form_id");

      formData.append("file", doc, doc.name);
      formData.append("type_doc", ccType);
      formData.append("id", idFromLS);
      formData.append("insurance", "CC");

      endpointWithParams = `${baseEndpoint}?_id=${idFromLS}&insurance=CC`;
    } else {
      const idFromLS = localStorage.getItem("form_id");

      const valueInsurerArr = insuranceVal.split("-");

      localStorage.setItem("latestStep", "attach-policy");

      localStorage.setItem("insurance_id", valueInsurerArr[1]);

      formData.append("file", doc, doc.name);
      formData.append("type_doc", docType ? docType : "other");
      formData.append("id", idFromLS);
      formData.append("insurance", insuranceVal);
      endpointWithParams = `${baseEndpoint}?_id=${idFromLS}&insurance=${insuranceVal}`;
    }

    try {
      const idFromLS = localStorage.getItem("form_id");

      const emailFormData = new FormData();

      const message = `Hola, has recibido una ${
        inputData.type === "document" ? "cedula" : "poliza"
      } de un cliente desde nuestro portal. Abre este correo para revisarlo.`;

      emailFormData.append("message", message);
      emailFormData.append("sender", inputData.sender);
      emailFormData.append("subject", inputData.subject);
      emailFormData.append("document", idFromLS);

      emailFormData.append(
        inputData.type === "document" ? "cc" : "policy",
        doc
      );

      fetch(inputData.emailEndpoint, {
        method: "POST",
        body: emailFormData,
      }).then((data) => {
        if (data.status === 200) {
          fetch(endpointWithParams, {
            method: "POST",
            body: formData,
          }).then(() => {
            if (location.pathname === "/admin/crear-credito/adjuntar-poliza") {
              const userData = JSON.parse(localStorage.getItem("userData"));

              if (userData && Object.keys(userData).length !== 0) {
                setPlate("");
                setDoc(null);
                setInputTextInfo("");
                setTimeout(() => {
                  setHasComplementTxt(false);
                  setLoading(false);
                  history("/admin/crear-credito/verificar-datos");
                }, 15000);
              } else {
                setPlate("");
                setDoc(null);
                setInputTextInfo("");
                setTimeout(() => {
                  setHasComplementTxt(false);
                  setLoading(false);
                  history(inputData.redirect);
                }, 15000);
              }
            } else {
              setPlate("");
              setDoc(null);
              setInputTextInfo("");
              setTimeout(() => {
                setHasComplementTxt(false);
                setLoading(false);
                history(inputData.redirect);
              }, 15000);
            }
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      encType="multipart/form-data"
      className="form-container-docs"
    >
      <div className="txt-input-container">
        {inputData.headerFields &&
          inputData.headerFields.map((field, i) => {
            return (
              <label
                className={`select-label ${field.isHidden ? "hide-label" : ""}`}
                key={i}
              >
                {field.title}
                <select
                  onChange={(e) => handleInput(e)}
                  name={field.name}
                  required={field.isRequired}
                >
                  <option value="">--Ninguno--</option>
                  {field.options.map((opt, i) => {
                    return (
                      <option
                        key={i}
                        value={opt.value}
                        selected={opt.isSelected}
                      >
                        {opt.title}
                      </option>
                    );
                  })}
                </select>
              </label>
            );
          })}
      </div>
      <div className="inputs-file-container">
        {inputData.inputs.map((input, i) => {
          return (
            <label key={i}>
              {input.labelTitle}
              <input
                type="file"
                onChange={(e) => handleInput(e)}
                accept={input.accept}
                name={input.name}
                required={input.isRequired}
                id={input.id}
              />
              <p className="input-subtitle">{input.labelSubtitle}</p>
            </label>
          );
        })}
      </div>
      <div className="form-doc-btn-container">
        <button title="Enviar" className="form-doc-btn" type="submit">
          Enviar
        </button>
      </div>
    </form>
  );
};

export default FormDocsComponent;
