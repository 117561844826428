import React, { useState, useEffect } from "react";

//Style
import "./PopupWithTableComponent.css";

//Components
import CreditDetailsTableComponent from "../CreditDetailsTableComponent/CreditDetailsTableComponent";

const PopupWithTableComponent = ({ dataPopup, getDataFromCreditTable, popupTableFields }) => {
  const [maxData, setMaxData] = useState(10);

  const [initPage, setInitPage] = useState(0);

  const [creditList, setCreditList] = useState([]);

  const [totalCredits, setTotalCredits] = useState(0);

  const [filterData, setFilterData] = useState({
    type: "",
    document_plate: "",
    advisor: "",
    insurer: "",
    credit_state: "En proceso",
    next_to_renew: "",
  });

  useEffect(() => {
    dataPopup && dataPopup.length > 0 && setCreditList(dataPopup);
  }, [dataPopup]);

  const getMaxDataValue = (maxDataVal) => {
    setMaxData(maxDataVal);
  };

  const getPaginationData = (initPageVal) => {
    setInitPage(initPageVal);
  };

  const closePopup = () => {
    getDataFromCreditTable([], false);
  };

  return (
    <div className="popup-with-table-component">
      <div className="popup-with-table-container">
        <div className="popup-with-table-subcontainer">
          <div className="pwt-title-container">
            <h1 className="pwt-title">Cuotas en Mora</h1>
          </div>
          <div className="pwt-description-txt-container">
            <p className="pwtdt-txt">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a
              diam sapien. Nulla sit amet diam volutpat, tempus ipsum vitae,
              hendrerit enim. Maecenas nec nulla id lorem placerat molestie.
            </p>
          </div>
          <div className="pwt-table-container">
            <CreditDetailsTableComponent
              getMaxData={getMaxDataValue}
              maxData={maxData}
              getPaginationInit={getPaginationData}
              credits={creditList}
              totalCreditsNum={totalCredits}
              filterData={filterData}
              isPopup={true}
              currentPath={"/admin/creditos-mora"}
              fields={popupTableFields}
            />
          </div>
          <div className="pwt-btns-container">
            <button
              onClick={closePopup}
              className="pwt-btn-close"
              title="Cerrar"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupWithTableComponent;
