import React, { useEffect, useState, useContext } from "react";
import { BACKEND_API } from "../../constants/backendApi";

//Style
import "./CreateCreditRenovationBody.css";

//Data
import createCreditRenovationData from "../../json/CreateCreditData/CreateCreditRenovationData.json";

//Components
import FormWithStepsComponent from "../FormWithStepsComponent/FormWithStepsComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";
import { PopupContext } from "../../context/PopupContext";

const CreateCreditRenovationBody = ({ userData }) => {
  const token = localStorage.getItem("token");

  const { setLoading } = useContext(LoadingContext);

  const { setShowPopup, showPopup, popupData } = useContext(PopupContext);

  const formId = localStorage.getItem("form_id");

  const [createCreditUserData, setCreateCreditUserData] = useState({});

  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    setShowPopup(false);
    setLoading(true);

    try {
      fetch(
        `${BACKEND_API}/api/credit-ocr?id=${formId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          },
        }
      )
        .then((res) => res.json())
        .then(({ data }) => {
          setCreateCreditUserData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {}
  }, [refreshData]);

  const getRefreshDataState = (newState) => {
    setRefreshData(newState);
  };

  return (
    <section className="create-credit-renovation-section">
      <div className="create-credit-renovation-subcontainer">
        <div className="form-with-steps-supercontainer">
          <FormWithStepsComponent
            hasSteps={true}
            createCreditUserData={createCreditUserData}
            refreshData={refreshData}
            getRefreshDataState={getRefreshDataState}
            inputData={createCreditRenovationData.data[0]}
            hasRestoreBtn={true}
            userData={userData}
          />
        </div>
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/bgs/create-credit-deco-img.webp"
          className="profile-deco"
          alt=""
        />
      </div>
    </section>
  );
};

export default CreateCreditRenovationBody;
