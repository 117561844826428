import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//Style
import "./CreditDetailsTableComponent.css";

const CreditDetailsTableComponent = ({
  getMaxData,
  getPaginationInit,
  maxData,
  credits,
  totalCreditsNum,
  filterData,
  isPopup,
  currentPath,
  fields,
}) => {
  const [pageCounter, setPageCounter] = useState(1);

  const [limit, setLimit] = useState(0);

  const [type, setType] = useState("next");

  const location = useLocation();

  const sendMaxData = (e) => {
    getMaxData(e.target.value);
  };

  const sendPaginationInit = () => {
    if (type === "next") {
      const nextVal = maxData * pageCounter - maxData;
      getPaginationInit(nextVal);
    } else if (type === "prev") {
      const prevVal = maxData * pageCounter - maxData;
      getPaginationInit(prevVal);
    }
  };

  useEffect(() => {
    sendPaginationInit();
  }, [pageCounter]);

  useEffect(() => {
    const totalPagesNum = Math.ceil(totalCreditsNum / maxData);

    setLimit(totalPagesNum);

    if (pageCounter >= limit) {
      setPageCounter(1);
    }
  }, [totalCreditsNum, maxData]);

  const sendPaginationInitNext = () => {
    pageCounter < limit && setPageCounter(pageCounter + 1);
    setType("next");
  };

  const sendPaginationInitPrev = () => {
    pageCounter > 1 && setPageCounter(pageCounter - 1);
    setType("prev");
  };

  return (
    <div className="credit-detail-table-container">
      <table className="cdt-table-container">
        <tbody>
          <tr className="cdt-table-row">
            {fields &&
              fields.map((field, i) => {
                return <td className="cdt-table-header-title">{field.name}</td>;
              })}
          </tr>
          {credits.map((credit, i) => {
            return (
              <tr key={i} className="cdt-table-row">
                {credit.map((field, i) => {
                  if (field.is_action_btn) {
                    return (
                      <td className="cdt-table-header-title actions-btn">
                        {field.btns.map((btn, i) => {
                          return (
                            <button
                              className="cdt-tht-action-btn"
                              onClick={btn.function}
                              title={btn.title}
                            >
                              <img
                                className="cdt-tht-action-btn-icon"
                                src={btn.icon_src}
                                alt=""
                              />
                            </button>
                          );
                        })}
                      </td>
                    );
                  } else {
                    return (
                      <td key={i} className="cdt-table-header-title">
                        {field.field_content}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!isPopup && (
        <div className="cdt-pagination-elms">
          <div className="pagination-btns-container">
            <div className="pagination-btns">
              <button
                title="Anterior"
                className="pagination-btn"
                onClick={sendPaginationInitPrev}
              >
                {"<"}
              </button>
              {pageCounter}
              <button
                title="Siguiente"
                className="pagination-btn"
                onClick={sendPaginationInitNext}
              >
                {">"}
              </button>
            </div>
            <div className="pagination-info">
              1 - {credits.length} de {totalCreditsNum}
            </div>
            <div className="num-elems-page">
              <select
                className="select-max-data"
                onChange={sendMaxData}
                name=""
                id=""
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreditDetailsTableComponent;
