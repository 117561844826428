import React, { useContext, useEffect, useState } from "react";

//Style
import "./CreateCreditValidateDocumentBody.css";

//Data
import validateInputs from "../../json/CreateCreditData/validateDocumentInputs.json";

//Components
import FormWithStepsComponent from "../FormWithStepsComponent/FormWithStepsComponent";

//Context
import { PopupContext } from "../../context/PopupContext";

const CreateCreditValidateDocumentBody = () => {
  const { setShowPopup, setPopupData } = useContext(PopupContext);

  const [latestPage, setLatestPage] = useState("");

  const isCreditInProcess = JSON.parse(
    localStorage.getItem("isCreditInProcess")
  );

  const creditProcessType = localStorage.getItem("processType");

  const latestStep = localStorage.getItem("latestStep");

  useEffect(() => {
    if (creditProcessType === "new-credit") {
      switch (latestStep) {
        case "validate-document":
          setLatestPage("/admin/crear-credito/adjuntar-documento");
          break;
        case "attach-document":
          setLatestPage("/admin/crear-credito/adjuntar-poliza");
          break;
        case "attach-policy":
          setLatestPage("/admin/crear-credito/formulario");
          break;
        case "complement-data":
          setLatestPage("/admin/crear-credito/formulario-confirmar");
          break;
      }
    } else if (creditProcessType === "renovation") {
      switch (latestStep) {
        case "validate-document":
          setLatestPage("/admin/crear-credito/adjuntar-poliza");
          break;
        case "attach-policy":
          setLatestPage("/admin/crear-credito/verificar-datos");
          break;
        case "verify-data":
          setLatestPage("/admin/crear-credito/formulario-renovacion");
          break;
        case "complement-data-renovation":
          setLatestPage("/admin/crear-credito/formulario-renovacion-confirmar");
          break;
      }
    }

    setShowPopup(true);
    setPopupData({
      hasTitle: true,
      hasInfoArr: true,
      title: "Tenga en Cuenta",
      infoArr: [
        "* Solo se aceptan créditos para persona natural y pólizas de carácter individual.",
        "* Aplica únicamente para las pólizas todo riesgo auto.",
        "* Para los demás casos, que no se encuentren dentro de las condiciones descritas anteriormente, usted deberá hacer el envío de la documentación a través del siguiente correo electrónico: fabrica@crediseguro.co.",
        "* Tenga en cuenta que si la financiación corresponde a las siguientes aseguradoras: AXA, ALLIANZ, PREVISORA, LIBERTY, SBS, BOLIVAR Y SURA, el tomador en la caratula de la póliza debe ser Crediseguro S.A. De lo contrario usted deberá tramitar el cambio de caratula con la correspondiente aseguradora.",
        isCreditInProcess
          ? "Tienes un crédito pendiente por terminar ¿Deseas continuar?"
          : "",
      ],
      type: isCreditInProcess ? "option" : "",
      showOptionBtn: isCreditInProcess,
      btnData: [
        {
          page: "login",
          type: "redirect",
          action: "redirect",
          text: "Si",
          title: "si",
          redirect: latestPage,
        },
        {
          page: "login",
          type: "redirect",
          action: "delete-data-ls",
          text: "No",
          title: "no",
          redirect: "/admin/crear-credito/validar-documento",
        },
      ],
    });
  }, [latestPage]);

  return (
    <section className="validate-document-section">
      <div className="validate-document-subcontainer">
        <div className="form-with-steps-supercontainer">
          <FormWithStepsComponent
            inputData={validateInputs.data[0]}
            hasSteps={false}
            hasRestoreBtn={false}
          />
        </div>
        <div>
          <h1>Hola mundo desde la subseccion de la tabla</h1>
        </div>
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/bgs/woman-deco-img-2.webp"
          className="profile-deco"
          alt=""
        />
      </div>
    </section>
  );
};

export default CreateCreditValidateDocumentBody;
