import { appVersion } from "./version";

let socket;

const connectWebSocket = () => {
  socket = new WebSocket("ws://localhost:3001");

  socket.onopen = () => {
    console.log("Connected to WebSocket server");
    socket.send(
      JSON.stringify({
        type: "version",
        version: appVersion,
      })
    );
  };

  socket.onmessage = (event) => {
    console.log("Message received:", event.data); // Añadir depuración aquí

    try {
      const message = JSON.parse(event.data);

      if (message.type === "WELCOME") {
        console.log("Welcome message:", message.message);
      } else if (message.type === "NEW_VERSION") {
        console.log("New version available:", message.version.version);
        if (message.version.version !== appVersion) {
          localStorage.setItem("show_noti", true);
          window.location.reload();
        }
      } else {
        console.log("Unhandled message type:", message);
      }
    } catch (error) {
      console.error("Error parsing message:", error);
    }
  };

  // socket.onclose = () => {
  //   console.log("Disconnected from WebSocket server");
  //   setTimeout(connectWebSocket, 5000); // Intenta reconectar después de 5 segundos
  // };

  socket.onerror = (error) => {
    console.error("WebSocket error:", error);
  };
};

connectWebSocket();

export default socket;
