import React, { useContext, useState } from "react";
import { BACKEND_API } from "../../constants/backendApi";

//Style
import "./DocCertificationTableComponent.css";

//Components
import DocCertificationElementComponent from "../DocCertificationElementComponent/DocCertificationElementComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";
import { PopupContext } from "../../context/PopupContext";

const DocCertificationTableComponent = ({
  creditStatusList,
  peaceAndBondCreditList,
  paymentReceiptCreditList,
}) => {
  const [peaceAndBondElms, setPeaceAndBondElms] = useState([]);

  const [creditStatusElms, setCreditStatusElms] = useState([]);

  const [paymentReceiptElms, setPaymentReceiptElms] = useState([]);

  const token = localStorage.getItem("token");

  const { setLoading } = useContext(LoadingContext);

  const { setShowPopup, setPopupData, showPopup, popupData } =
    useContext(PopupContext);

  const getPeaceAndBondData = (peaceAndBound) => {
    const existingIndex = peaceAndBondElms.findIndex(
      (item) => item.creditId === peaceAndBound.creditId
    );

    if (existingIndex !== -1) {
      const newElms = peaceAndBondElms.filter(
        (item) => item.creditId !== peaceAndBound.creditId
      );
      setPeaceAndBondElms(newElms);
    } else {
      setPeaceAndBondElms([...peaceAndBondElms, peaceAndBound]);
    }
  };

  const getCreditStatusData = (creditStatus) => {
    const existingIndex = creditStatusElms.findIndex(
      (item) => item.creditId === creditStatus.creditId
    );

    if (existingIndex !== -1) {
      const newElms = creditStatusElms.filter(
        (item) => item.creditId !== creditStatus.creditId
      );
      setCreditStatusElms(newElms);
    } else {
      setCreditStatusElms([...creditStatusElms, creditStatus]);
    }
  };

  const getPaymentReceiptData = (paymentReceipt) => {
    const existingIndex = paymentReceiptElms.findIndex(
      (item) => item.creditId === paymentReceipt.creditId
    );

    if (existingIndex !== -1) {
      const newElms = paymentReceiptElms.filter(
        (item) => item.creditId !== paymentReceipt.creditId
      );
      setPaymentReceiptElms(newElms);
    } else {
      setPaymentReceiptElms([...paymentReceiptElms, paymentReceipt]);
    }
  };

  const handleGetCertificates = () => {
    const joinedArr = peaceAndBondElms.concat(
      creditStatusElms,
      paymentReceiptElms
    );

    const body = {
      data: joinedArr,
    };

    if (body.data.length !== 0) {
      setLoading(true);
      try {
        fetch(`${BACKEND_API}/api/certificate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data && data.message === "Certificados") {
              setShowPopup(true);
              setPopupData({
                title: "Enviado Exitosamente",
                info: "Tus certificados han sido enviados al correo electrónico registrado.",
                type: "correct",
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="doc-certification-table-container">
      <div className="doc-certification-table-subcontainer">
        <div className="dct-section-container first-section">
          <div className="dct-section-doc-list-container">
            <h1 className="dct-sdl-title">Paz y Salvo</h1>
            <div className="dct-sdl-info-container">
              {peaceAndBondCreditList &&
                peaceAndBondCreditList.map((pabcredit, i) => {
                  return (
                    <DocCertificationElementComponent
                      key={i}
                      docData={pabcredit}
                      customFunction={getPeaceAndBondData}
                      type={"paz_y_salvo"}
                    />
                  );
                })}
            </div>
          </div>
          <div className="dct-section-doc-list-container">
            <h1 className="dct-sdl-title">Estado del Crédito</h1>
            <div className="dct-sdl-info-container">
              {creditStatusList &&
                creditStatusList.map((creditStatus, i) => {
                  return (
                    <DocCertificationElementComponent
                      key={i}
                      docData={creditStatus}
                      customFunction={getCreditStatusData}
                      type={"Estado_del_credito"}
                    />
                  );
                })}
            </div>
          </div>
          <div className="dct-section-doc-list-container">
            <h1 className="dct-sdl-title">Recibo de Pago</h1>
            <div className="dct-sdl-info-container">
              {paymentReceiptCreditList &&
                paymentReceiptCreditList.map((prcredit, i) => {
                  return (
                    <DocCertificationElementComponent
                      key={i}
                      docData={prcredit}
                      customFunction={getPaymentReceiptData}
                      type={"Recibo_de_pago"}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div className="dct-section-container">
          <button
            onClick={handleGetCertificates}
            className="dct-section-btn"
            title="Solicitar"
          >
            Solicitar
          </button>
        </div>
        <div className="dct-section-container">
          <p className="dct-section-txt">
            La mejor opción para financiar tu tranquilidad.
          </p>
          <img
            className="dct-deco-img"
            src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/logo.webp"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default DocCertificationTableComponent;
