import React, { useContext } from "react";

//Data
import popupDataBtn from "../../json/CreateCreditData/PopupBtnData.json";

//Components
import VersionTxtComponent from "../../components/VersionTxtComponent/VersionTxtComponent";
import NavbarComponent from "../../components/NavbarComponent/NavbarComponent";
import NavbarMobile from "../../components/NavbarMobile/NavbarMobile";
import NavbarMobileBottom from "../../components/NavbarMobileBotton/NavbarMobileBottom";
import SideMenusComponent from "../../components/SideMenusComponent/SideMenusComponent";
import DocCertificationBody from "../../components/body/DocCertificationBody/DocCertificationBody";
import LoadingScreenComponent from "../../components/LoadingScreenComponent/LoadingScreenComponent";
import PopUpComponent from "../../components/PopUpComponent/PopUpComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";
import { PopupContext } from "../../context/PopupContext";

const DocCertificationScreen = ({ userData, userMenuData }) => {
  const { loading } = useContext(LoadingContext);

  const { showPopup, popupData } = useContext(PopupContext);

  return (
    <>
      {loading && <LoadingScreenComponent />}
      {showPopup && (
        <PopUpComponent content={popupData} btnData={popupDataBtn.data[0]} />
      )}
      <div className="body-container">
        <VersionTxtComponent />
        <div className="body-subcontainer">
          <NavbarComponent userData={userData} />
          <NavbarMobile userData={userData} menuData={userMenuData} />
          <DocCertificationBody userData={userData} />
          <NavbarMobileBottom />
        </div>
        <SideMenusComponent menuData={userMenuData} />
      </div>
    </>
  );
};

export default DocCertificationScreen;
