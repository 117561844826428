import React, { useState, useEffect, useContext } from "react";
import { encode as base64_encode, encode } from "base-64";
import { BACKEND_API } from "../../constants/backendApi";

//Style
import "./ProfileFormComponent.css";

//Context
import { UserDataContext } from "../../context/UserDataContext";
import { LoadingContext } from "../../context/LoadingContext";
import { PopupContext } from "../../context/PopupContext";

const ProfileFormComponent = ({ userData, token }) => {
  const { setShowPopup, setPopupData } = useContext(PopupContext);

  const [showBtns, setShowBtns] = useState(false);

  const [showPass, setShowPass] = useState(false);

  const { setCurrentUser } = useContext(UserDataContext);

  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setUserDataForm(userData);
  }, [userData]);

  const [userDataForm, setUserDataForm] = useState({
    email: userData.email,
    document: userData.document,
    phone: userData.phone,
    password: userData.password,
    confirm_password: userData.confirm_password,
  });

  const [disabledInputs, setDisabledInputs] = useState({
    email: true,
    phone: true,
    password: true,
    confirm_password: true,
  });

  const handleGetData = (e) => {
    if (
      disabledInputs.password &&
      (!disabledInputs.email || !disabledInputs.phone)
    ) {
      setShowBtns(true);
    } else if (
      !disabledInputs.password &&
      (disabledInputs.email || disabledInputs.phone)
    ) {
      setShowBtns(true);
    } else if (
      !disabledInputs.email ||
      (!disabledInputs.phone && !disabledInputs.password)
    ) {
      setShowBtns(false);
    }

    if (e.target.title === "email") {
      const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!regexEmail.test(e.target.value)) {
        e.target.parentElement.classList.add("border-err");
      } else {
        e.target.parentElement.classList.remove("border-err");
      }
    }

    if (e.target.title == "phone") {
      e.target.value = e.target.value.replace(/\s/g, "").replace(/\D/g, "");

      if (e.target.value.length > 10) {
        e.target.value = e.target.value.substring(0, 10);
      }

      if (
        e.target.value[0] !== "3" ||
        e.target.value[1] === "3" ||
        e.target.value.trim() === "" ||
        e.target.value.length < 10
      ) {
        e.target.parentElement.classList.add("border-err");
      } else {
        e.target.parentElement.classList.remove("border-err");
      }
    }

    if (e.target.title == "password") {
      e.target.value = e.target.value.replace(/\s/g, "");

      const isValidPassword = validatePassword(e.target.value);
      if (e.target.value !== userDataForm.confirm_password) {
        setShowBtns(false);
      }
      if (!isValidPassword) {
        e.target.parentElement.classList.add("border-err");
        setShowBtns(false);
      } else {
        e.target.parentElement.classList.remove("border-err");
      }
    }

    if (e.target.title == "confirm_password") {
      e.target.value = e.target.value.replace(/\s/g, "");

      if (userDataForm.password && userDataForm.password !== "") {
        const isValidPassword = validatePassword(e.target.value);

        if (e.target.value !== userDataForm.password) {
          setShowBtns(false);
        }
        if (!isValidPassword) {
          e.target.parentElement.classList.add("border-err");
          setShowBtns(false);
        } else {
          e.target.parentElement.classList.remove("border-err");
        }
      }
    }

    setUserDataForm({
      ...userDataForm,
      [e.target.name]: e.target.value,
    });
  };

  const validatePassword = (password) => {
    if (password.length < 6) {
      return false;
    }

    if (!/[A-Z]/.test(password)) {
      return false;
    }

    if (!/\d/.test(password)) {
      return false;
    }

    if (!/[$&+,:;=?@#|'<>.^*()%!-]/.test(password)) {
      return false;
    }

    return true;
  };

  const handleEditData = (e) => {
    setDisabledInputs({
      ...disabledInputs,
      [e.target.name]: !disabledInputs[e.target.name],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      !disabledInputs.password &&
      disabledInputs.email &&
      disabledInputs.phone
    ) {
      let encoded = base64_encode(userDataForm.password);

      if (
        userDataForm.password &&
        userDataForm.password !== "" &&
        userDataForm.confirm_password &&
        userDataForm.confirm_password !== "" &&
        userDataForm.confirm_password === userDataForm.password
      ) {
        try {
          const body = {
            newPass: encoded,
          };
          fetch(`${BACKEND_API}/api/change-password`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.message) {
                setShowBtns(false);
                setUserDataForm(data);
                setShowPass(false);
                setUserDataForm({
                  ...userData,
                  password: "",
                  confirm_password: "",
                });
                setDisabledInputs({
                  email: true,
                  phone: true,
                  password: true,
                });
                setShowPopup(true);
                setPopupData({
                  title: "Hecho",
                  info: "La contraseña ha sido actualizada exitosamente.",
                  type: "correct",
                });
              }
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (err) {
          console.log(err);
        }
      } else {
        setShowPopup(true);
        setPopupData({
          title: "Error",
          info: "La contraseña no es valida.",
          type: "error",
        });
        setLoading(false);
        setShowBtns(false);
        setShowPass(false);
        setUserDataForm({
          ...userData,
          password: "",
          confirm_password: "",
        });
        setDisabledInputs({
          email: true,
          phone: true,
          password: true,
        });
      }
    } else if (
      (!disabledInputs.email || !disabledInputs.phone) &&
      disabledInputs.password
    ) {
      try {
        const body = {
          phone: userDataForm.phone,
          email: userDataForm.email,
        };
        fetch(`${BACKEND_API}/api/user`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then(({ data }) => {
            localStorage.setItem("token", data);
            const newToken = localStorage.getItem("token");
            try {
              fetch(`${BACKEND_API}/api/user`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${newToken}`
                },
              })
                .then((res) => res.json())
                .then(({ data }) => {
                  setCurrentUser(data);
                  setShowBtns(false);
                  setDisabledInputs({
                    email: true,
                    phone: true,
                  });
                  setShowPopup(true);
                  setPopupData({
                    title: "Hecho",
                    info: "Datos de contacto actualizados exitosamente.",
                    type: "correct",
                  });
                })
                .finally(() => {
                  setLoading(false);
                });
            } catch (err) {
              console.log(err);
            }
          });
      } catch (err) {
        console.log(err);
      }
    } else if (
      !disabledInputs.email ||
      !disabledInputs.phone ||
      !disabledInputs.password
    ) {
      try {
        fetch(`${BACKEND_API}/api/user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          },
        })
          .then((res) => res.json())
          .then(({ data }) => {
            setShowBtns(false);
            setUserDataForm(data);
            setShowPass(false);
            setUserDataForm({
              ...userData,
              password: "",
              confirm_password: "",
            });
            setDisabledInputs({
              email: true,
              phone: true,
              password: true,
            });
            setShowPopup(true);
            setPopupData({
              title: "Error",
              info: "Esta acción no es posible, solo puedes actualizar la contraseña o los datos de contacto por separado.",
              type: "error",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleCancelEdit = () => {
    setLoading(true);
    try {
      fetch(`${BACKEND_API}/api/user`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        },
      })
        .then((res) => res.json())
        .then(({ data }) => {
          setShowBtns(false);
          setUserDataForm(data);
          setShowPass(false);
          setUserDataForm({
            ...userData,
            password: "",
            confirm_password: "",
          });
          setDisabledInputs({
            email: true,
            phone: true,
            password: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="profile-form-container">
      <div className="pf-subcontainer">
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/home/assets/logo.webp"
          alt=""
          className="pf-logo"
        />
        <form
          onChange={handleGetData}
          onSubmit={handleSubmit}
          className="pf-inputs-container"
        >
          <label className="label">
            <img
              src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/doc-icon.svg"
              alt=""
              className="input-icon"
            />
            <input
              type="text"
              placeholder="Número de Cédula o NIT"
              disabled
              value={userDataForm.document}
            />
          </label>
          <label className="label">
            <img
              src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/email-icon.svg"
              alt=""
              className="input-icon"
            />
            <input
              name="email"
              type="text"
              title="email"
              placeholder="Correo Electrónico"
              value={userDataForm.email}
              disabled
            />
          </label>
          <label className="label">
            <img
              src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/phone-icon.svg"
              alt=""
              className="input-icon phone-fix"
            />
            <input
              name="phone"
              type="text"
              title="phone"
              placeholder="Celular"
              value={userDataForm.phone}
              disabled
            />
          </label>
          <label className="label">
            <img
              src={
                showPass
                  ? "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/hide-pass-icon-profile.webp"
                  : "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/show-pass-icon-profile.webp"
              }
              alt=""
              className="input-icon"
              onClick={() => setShowPass(!showPass)}
            />
            <input
              name="password"
              type={showPass ? "text" : "password"}
              title="password"
              placeholder="Nueva Contraseña"
              value={userDataForm.password}
              disabled={disabledInputs.password}
              required
            />
            <img
              src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/check-icon.webp"
              name="password"
              onClick={handleEditData}
              alt=""
              className="edit-icon"
              title="Editar"
            />
          </label>
          <p
            className={`password-advice ${
              userDataForm.password ? "pa-show" : ""
            }`}
          >
            La contraseña debe contener números, letras, al menos una mayúscula
            y caracteres especiales.
          </p>
          <label className="label">
            <img
              src={
                showPass
                  ? "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/hide-pass-icon-profile.webp"
                  : "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/show-pass-icon-profile.webp"
              }
              alt=""
              className="input-icon"
              onClick={() => setShowPass(!showPass)}
            />
            <input
              name="confirm_password"
              type={showPass ? "text" : "password"}
              title="confirm_password"
              placeholder="Confirmar Nueva Contraseña"
              value={userDataForm.confirm_password}
              disabled={disabledInputs.password}
              required
            />
          </label>
          {showBtns && (
            <div className="pf-btn-container">
              <button
                onClick={handleCancelEdit}
                type="button"
                className="pf-btn cancel"
                title="Cancelar"
              >
                Cancelar
              </button>
              <button className="pf-btn update" title="Actualizar">
                Actualizar
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ProfileFormComponent;
