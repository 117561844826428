import React, { useEffect, useState, useContext } from "react";
import { utils, writeFile } from "xlsx";
import { BACKEND_API } from "../../constants/backendApi";

//Style
import "./CertificationBody.css";

//Components
import CreditDetailsTableComponent from "../CreditDetailsTableComponent/CreditDetailsTableComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";

//hooks
import useDebounce from "../../hooks/useDebounce";

//Context
import { PopupContext } from "../../context/PopupContext";

const CertificationBody = ({ getDataFromCreditTable }) => {
  const { setLoading } = useContext(LoadingContext);

  const [maxData, setMaxData] = useState(10);

  const [initPage, setInitPage] = useState(0);

  const [creditList, setCreditList] = useState([]);

  const [creditListExcel, setCreditListExcel] = useState([]);

  const [totalCredits, setTotalCredits] = useState(0);

  const [totalCurrentLoans, setTotalCurrentLoans] = useState(0);

  const { setShowPopup, setPopupData, showPopup, popupData } =
    useContext(PopupContext);

  const [filterData, setFilterData] = useState({
    type: "",
    document_plate: "",
    advisor: "",
    insurer: "",
    credit_state: "",
    next_to_renew: "",
  });

  const tableFields = [
    {
      name: "Altura",
    },
    {
      name: "Nombre Cliente",
    },
    {
      name: "Aseguradora",
    },
    {
      name: "No. de Documento",
    },
    {
      name: "Dias de Mora",
    },
    {
      name: "Placa/Detalle de Riesgo",
    },
    {
      name: "No. de Poliza",
    },
    {
      name: "Acciones",
    },
  ];

  const token = localStorage.getItem("token");

  useEffect(() => {
    try {
      const selectInsurer = document.getElementById("insurer");

      fetch(`${BACKEND_API}/api/insurance-companies`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      })
        .then((res) => res.json())
        .then(({ data }) => {
          data.map((insurer) => {
            const option = document.createElement("option");
            option.text = insurer.NombreAseguradora;
            option.value = insurer.IdAseguradora;
            selectInsurer.appendChild(option);

            const optionsArray = Array.from(selectInsurer.options);

            optionsArray.sort((a, b) => a.text.localeCompare(b.text));
          });
        });
    } catch (err) {
      console.log(err);
    }

    const filterBody = {
      Filtro: filterData.document_plate,
      EstadoConsulta: "",
      IdAseguradora: filterData.insurer,
      Estado: "",
      AllData: true,
      Renovacion: false,
      Mora: true,
      EnProceso: false,
      NumeroRegistros: maxData,
      Inicial: initPage,
      Anio: "0",
      Mes: "0",
    };

    try {
      fetch(`${BACKEND_API}/api/credits`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filterBody),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data.Creditos) {
            setCreditListExcel(data.Creditos);
          } else {
            setCreditList([]);
            setTotalCredits(0);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const sendDataFromCreditTable = (creditData) => {
    getDataFromCreditTable(creditData, true);
  };

  const sendCertificate = (creditData) => {
    const body = {
      data: [creditData],
    };

    if (body.data.length !== 0) {
      setLoading(true);
      try {
        fetch(`${BACKEND_API}/api/certificate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data && data.message === "Certificados") {
              setShowPopup(true);
              setPopupData({
                title: "Enviado Exitosamente",
                info: "Tu certificado ha sido enviado al correo electrónico registrado.",
                type: "correct",
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const debouncedSearch = useDebounce((filterData) => {
    setLoading(true);

    const filterBody = {
      Filtro: filterData.document_plate,
      EstadoConsulta: "",
      IdAseguradora: filterData.insurer,
      Estado: "",
      AllData: false,
      Renovacion: false,
      Mora: true,
      EnProceso: false,
      NumeroRegistros: maxData,
      Inicial: initPage,
      Anio: "0",
      Mes: "0",
    };

    if (
      filterData.type === "FiltrosVarios" &&
      filterData.advisor === "" &&
      filterData.insurer === "" &&
      filterData.credit_state === ""
    ) {
      filterBody.TipoFiltro = "";
    }

    try {
      fetch(`${BACKEND_API}/api/credits`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filterBody),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data && data.Creditos) {
            const creditsNewArr = data.Creditos.map((credit) => [
              {
                field_content: credit.NumCuota + " de " + credit.No_Cuotas,
              },
              {
                field_content: credit.NombreCliente,
              },
              {
                field_content: credit.Aseguradora,
              },
              {
                field_content: credit.Document,
              },
              {
                field_content: credit.DiasMora,
              },
              {
                field_content: credit.Placa
                  ? credit.Placa
                  : credit.detalleRiesgo,
              },
              {
                field_content: credit.NoPoliza,
              },
              {
                is_action_btn: true,
                btns: [
                  {
                    function: () =>
                      sendDataFromCreditTable(credit.CuotasEnMora),
                    title: "Cuotas en Mora",
                    icon_src:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/deliquent-credit-icon-white.webp",
                  },
                  {
                    function: () =>
                      sendCertificate({
                        creditId: credit.credito,
                        clientId: credit.IdClient,
                        nameCertificate: "Recibo_de_pago",
                      }),
                    title: "Enviar Certificado",
                    icon_src: "/assets/temp/reference-icon.png",
                  },
                ],
              },
            ]);

            setTotalCurrentLoans(data.TotalCreditosVigentes);
            setTotalCredits(data.TotalRegistros);
            setCreditList(creditsNewArr);
          } else {
            setCreditList([]);
            setTotalCredits(0);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, 500);

  useEffect(() => {
    debouncedSearch(filterData);
  }, [maxData, initPage, filterData]);

  const getMaxDataValue = (maxDataVal) => {
    setMaxData(maxDataVal);
  };

  const getPaginationData = (initPageVal) => {
    setInitPage(initPageVal);
  };

  const handleGetFilters = (e) => {
    if (e.target.name === "document_plate") {
      setFilterData({
        ...filterData,
        type: e.target.value !== "" ? "Documento_Placa" : "",
        [e.target.name]: e.target.value,
      });
    }

    if (
      e.target.name === "advisor" ||
      e.target.name === "insurer" ||
      e.target.name === "credit_state"
    ) {
      setFilterData({
        ...filterData,
        type: "FiltrosVarios",
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCleanFilters = () => {
    setFilterData({
      type: "",
      document_plate: "",
      advisor: "",
      insurer: "",
      credit_state: "",
      next_to_renew: "",
    });
  };

  const exportToExcel = () => {
    const jsonData = creditListExcel.map((credit) => {
      return {
        Altura: credit.NumCuota + " de " + credit.No_Cuotas,
        NombreCliente: credit.NombreCliente,
        Aseguradora: credit.Aseguradora,
        NoDocumento: credit.Document,
        DiasMora: credit.DiasMora,
        PlacaDetalleRiesgo: !credit.Placa ? credit.detalleRiesgo : credit.Placa,
        NoPoliza: credit.NoPoliza,
      };
    });

    const workbook = utils.book_new();

    const worksheet = utils.json_to_sheet(jsonData);

    utils.book_append_sheet(workbook, worksheet, "Creditos en mora");

    writeFile(workbook, "Creditos en mora.xlsx");
  };

  return (
    <section className="certification-section">
      <div className="certification-subcontainer">
        <div className="c-title-container">
          <h1 className="c-title">Créditos en Mora</h1>
        </div>
        <div className="c-description-txt-container">
          <p className="cdt-txt">
            Con esta información nos puedes ayudar a gestionar los créditos en
            mora para evitar la cancelación.
          </p>
          <p className="cdt-txt">Número de créditos en mora: {totalCredits}</p>
          <p className="cdt-txt">
            Número de créditos vigentes: {totalCurrentLoans}
          </p>
        </div>
        <div className="filter-elms-container">
          <div className="input-container">
            <form onChange={handleGetFilters} className="filter-form-container">
              <label>
                <h1 className="label-title">Número de Documento o Placa:</h1>
                <input
                  className="filter-input-search"
                  type="text"
                  placeholder="Numero de Documento o Placa"
                  title="Numero de Documento o Placa"
                  name="document_plate"
                  value={filterData.document_plate}
                />
              </label>
              <label>
                <h1 className="label-title">Aseguradora:</h1>
                <select
                  name="insurer"
                  id="insurer"
                  disabled={filterData.document_plate !== "" ? true : false}
                  value={filterData.insurer}
                >
                  <option value="">--Seleccione--</option>
                </select>
              </label>
            </form>
            <button
              className="c-btn-search"
              title="Limpiar Filtros"
              onClick={handleCleanFilters}
            >
              Limpiar Filtros
            </button>
          </div>
        </div>
        <div
          className="cip-download-excel-container"
          style={{ display: creditList.length > 0 ? "unset" : "none" }}
        >
          <button
            title="Descargar Excel"
            className="cip-de-btn"
            onClick={() => exportToExcel()}
          >
            Descargar Excel
          </button>
        </div>
        <div className="credit-details-table-supercontainer">
          <CreditDetailsTableComponent
            getMaxData={getMaxDataValue}
            maxData={maxData}
            getPaginationInit={getPaginationData}
            credits={creditList}
            totalCreditsNum={totalCredits}
            filterData={filterData}
            fields={tableFields}
          />
        </div>
      </div>
    </section>
  );
};

export default CertificationBody;
