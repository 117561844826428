import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

//Components
import NavbarComponent from "../../components/NavbarComponent/NavbarComponent";
import SideMenusComponent from "../../components/SideMenusComponent/SideMenusComponent";
import AttachDocBody from "../../components/AttachDocBody/AttachDocBody";
import LoadingScreenComponent from "../../components/LoadingScreenComponent/LoadingScreenComponent";
import NavbarMobile from "../../components/NavbarMobile/NavbarMobile";
import NavbarMobileBottom from "../../components/NavbarMobileBotton/NavbarMobileBottom";
import VersionTxtComponent from "../../components/VersionTxtComponent/VersionTxtComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const AttachDocScreen = ({ userData, userMenuData }) => {
  const { loading, hasComplementTxt } = useContext(LoadingContext);

  const history = useNavigate();

  const currentStep = localStorage.getItem("latestStep");

  switch (currentStep) {
    case "attach-document":
      history("/admin/crear-credito/adjuntar-poliza");
      break;
    case "attach-policy":
      history("/admin/crear-credito/formulario");
      break;
    case "complement-data":
      history("/admin/crear-credito/formulario-confirmar");
      break;
  }

  return (
    <>
      {loading && (
        <LoadingScreenComponent hasComplementTxt={hasComplementTxt} />
      )}
      <div className="body-container">
        <VersionTxtComponent />
        <div className="body-subcontainer">
          <NavbarComponent userData={userData} />
          <NavbarMobile userData={userData} menuData={userMenuData} />
          <AttachDocBody />
          <NavbarMobileBottom />
        </div>
        <SideMenusComponent menuData={userMenuData} />
      </div>
    </>
  );
};

export default AttachDocScreen;
