import React, { useState, useEffect } from "react";

const useDebounce = (callback, delay) => {
  const [timer, setTimer] = useState(false);

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  function debounceSearch(...args) {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      callback(...args);
    }, delay);

    setTimer(newTimer);
  }

  return debounceSearch;
};

export default useDebounce;
