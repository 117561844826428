import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

//Style
import "./NavbarMobile.css";

const NavbarMobile = ({ userData, menuData }) => {
  const location = useLocation();

  const [message, setMessage] = useState("");

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      setMessage("buenos dias");
    } else if (currentHour >= 12 && currentHour < 18) {
      setMessage("buenas tardes");
    } else {
      setMessage("buenas noches");
    }
  }, []);

  return (
    <nav className="navbar-mobile-container">
      <div className="nbm-first-half-container">
        <div className="logo-img-container">
          <img src="/assets/logo.webp" alt="" className="logo-img" />
        </div>
        <div className="nbm-greetings">
          Hola {message}, {userData.name}
        </div>
      </div>
      <div className="nbm-second-half-container">
        <div className="menus-container">
          {menuData.map((option, i) => {
            return (
              <Link
                title={option.title}
                key={i}
                to={option.redirect}
                className={`option-container ${
                  option.redirect === location.pathname && "current-select"
                }`}
              >
                <img
                  src={
                    option.redirect === location.pathname
                      ? option.imgSelect
                      : option.img
                  }
                  alt={option.altImg}
                  className="option-icon"
                />
                <p className="title-option">{option.text}</p>
              </Link>
            );
          })}
        </div>
      </div>
    </nav>
  );
};

export default NavbarMobile;
