import React, { useState } from "react";

//Style
import "./PopupWithFormComponent.css";

const PopupWithFormComponent = () => {
  const [popupFormData, setPopupFormData] = useState({});

  const handleGetFormData = (e) => {
    setPopupFormData({
      ...popupFormData,
      [e.target.name]: e.target.value,
    });
    console.log(e);
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    console.log(popupFormData);

  };

  return (
    <div className="popup-with-form-component">
      <div className="popup-with-form-container">
        <div className="popup-with-form-subcontainer">
          <h1 className="pwf-title">Motivo de No Renovación</h1>
          <form
            action=""
            onChange={handleGetFormData}
            onSubmit={handleSaveChanges}
            className="pwf-form-container"
          >
            <select name="note" id="" className="pwf-input-form">
              <option value="">--Seleccione--</option>
              <option value="hola">Hola Mundo</option>
              <option value="hola">Adios Mundo</option>
            </select>
            <div className="pwf-btns-container">
              <button className="pwf-btn cancel-btn">Cancelar</button>
              <button className="pwf-btn">Guardar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopupWithFormComponent;
